const actionTypes = require('../constants/actionTypes');
const {MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } = require('constants/index');

function resize(screenWidth){
    let type;

    if (!screenWidth){
        screenWidth = window.screen && window.innerWidth || 0;
    }

    if (screenWidth <= MOBILE_MAX_WIDTH){
        type = actionTypes.SCREEN_IS_MOBILE
    } else if (screenWidth > TABLET_MAX_WIDTH){
        type = actionTypes.SCREEN_IS_DESKTOP
    } else {
        type = actionTypes.SCREEN_IS_TABLET;
    }

    return {
        type
    }
}

const showAppHeader = () => ({
    type: actionTypes.SCREEN_SHOW_APP_HEADER
});

const hideAppHeader = () => ({
    type: actionTypes.SCREEN_HIDE_APP_HEADER
});

const showAppFooter = () => ({
    type: actionTypes.SCREEN_SHOW_APP_FOOTER
});

const hideAppFooter = () => ({
    type: actionTypes.SCREEN_HIDE_APP_FOOTER
});

module.exports = {
    hideAppHeader,
    hideAppFooter,
    resize,
    showAppHeader,
    showAppFooter
}
